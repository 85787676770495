import React from "react";
import  { useState } from 'react';


function Content() {
  
  const [content, setContent] = useState( <div class="d-flex">
  <div class="container my-4"style={{marginLeft:"250px" }}>
    <img src="./assets/img/scop.png" alt="ylu" style={{width:"200px"}} />
  </div>
   <div class="container my-3 col-8">
     <h2 class="h2 pb-3">Scoping</h2>
        <p class="pb-2 light-300">
          Conduct a walkthrough of the facility to ascertain the equipment type, equipment counts, and collect operational details from the facility personnel. A scoping visit will provide a sense of possible energy efficiency opportunities at the facility.
       </p>
    </div>
</div>);

  const handleButtonClick = (buttonId) => {
  
   
    if (buttonId === 'button1') {
      setContent(
       
       

    <div class="d-flex">
        <div class="container my-4"style={{marginLeft:"250px" }}>
          <img src="./assets/img/scop.png" alt="ylu" style={{width:"200px"}} />
           </div>
             <div class="container my-3 col-8">
              <h2 class="h2 pb-3">Scoping</h2>
            <p class="pb-2 light-300">
            Conduct a walkthrough of the facility to ascertain the equipment type, equipment counts, and collect operational details from the facility personnel. A scoping visit will provide a sense of possible energy efficiency opportunities at the facility.
        </p>
      </div>
    </div>

      );
    } else if (buttonId === 'button2') {
      setContent(
      
      <div class="d-flex">
        <div class="container my-4"style={{marginLeft:"250px" }}>
          <img src="./assets/img/inve.png" alt="ylu" style={{width:"200px"}} />
        </div>

            <div class="container my-3 col-8">
                  <h2 class="h2 pb-3">Investigaton</h2>
                  <p class=" pb-2 light-300">
                  •	Data Collection: Install data loggers and/or set up trends in the existing control system to collect data at the required interval and for the required timeline <br />
                  •	Data Analytics: Analyze field data to ascertain the baseline operation and identify existing anomalies <br />
                  •	Energy Modeling: Create spreadsheet-based models using statistical techniques and engineering principals to quantify energy and cost savings <br/>
                  •	Reporting: Crating Engineering Reports detailing the existing anomalies and ways to rectify the anomalies in the from of energy efficiency measures (EEMs). </p>
              </div>
       </div>);
       } 
       
       else if (buttonId === 'button3') {
      setContent(
      
      
      <div class="d-flex">
        <div class="container my-4"style={{marginLeft:"250px" }}>
          <img src="./assets/img/impl.png" alt="ylu" style={{width:"200px"}} />
            </div>
            <div class="container my-3 col-8">
              <div class="banner-content align-self-center">
                  <h2 class="h2 pb-3">Implementation Support</h2>
                  <p class=" pb-2 light-300">
                  ORBIT in collaboration with AIML Ware can provide comprehensive control solutions to implement the EEMs as intended to realize the full savings potential of the facility.
                 </p>
              </div>
          </div>
  </div>);

    } else if (buttonId === 'button4') {
      setContent(
      <div class="d-flex">
        <div class="container my-4"style={{marginLeft:"250px" }}>
          <img src="./assets/img/meas.png" alt="ylu" style={{width:"200px"}} />
        </div>
      <div class="container my-3 col-8">
         
              <div class="banner-content align-self-center">
                  <h2 class="h2 pb-3">Measurement and Verification</h2>
                  <p class=" pb-2 light-300">
                  Post implementation, perform measurement and verification (M&V) using IPMVP protocol to ascertain proper implementation. </p>
              </div>
            </div>
       </div>)
    }
    };
  return (
    <div>
      <div class="service-tag py-5 " style={{backgroundColor:"rgb(181, 160, 210)"}}>
      <div class="col-md-12">
      <ul class="nav d-flex justify-content-center">
            <li class="nav-item mx-lg-4" >
                     <button className="filter-btn nav-link active shadow rounded-pill text-light px-4 light-30" style={{backgroundColor:"purple"}} onClick={() => handleButtonClick('button1')} >Scoping </button></li>
             <li class="nav-item mx-lg-4">
                     <button className="filter-btn nav-link  active shadow rounded-pill text-light px-4 light-30" style={{backgroundColor:"purple"}} onClick={() => handleButtonClick('button2')} >Investigation</button></li>
            <li class="filter-btn nav-item mx-lg-4"> 
                      <button className="filter-btn nav-link active shadow rounded-pill text-light px-4 light-30" style={{backgroundColor:"purple"}} onClick={() => handleButtonClick('button3')} >Implementation Support</button></li>
      <li class="nav-item mx-lg-4">
                       <button className="filter-btn nav-link active shadow rounded-pill text-light px-4 light-30" style={{backgroundColor:"purple"}} onClick={() => handleButtonClick('button4')} >Measurement and Verification</button> </li>
              </ul>
           </div>   
      </div> 


   
                    <p style={{ fontSize: '20px' }}>{content}</p></div>
           
       
    
    
  );
}

 export default Content;