import React from "react";
import logo1 from "../logo1.png";
import { Link } from "react-router-dom";


function Footer() {
  return (
    <div style={{color:"black"}}>
    <footer class=" pt-0" style={{backgroundColor:"rgb(181, 160, 210)"}}>
        <div class="container">
            <div class="row ">

                

                <div class="col-lg-4 col-12 my-sm-5 align-left">
                    <a class="" href="index.html">
                        
                   
                    <Link to="/"> <img src={logo1} alt="logo" style={{width:"220px", backgroundColor: 'transparent'}}/></Link><br />
                        
                    </a>
                    <p class=" my-lg-4 my-4"  >
                    Address: Kuvempunagar,<br/>
                     Mysore, Karnataka
                    </p>
                    
                </div>
                

                <div class="col-lg-4 col-md-8 my-sm-5 mt-4">
                    <h2 class="h4 pb-lg-3  light-300">Our Company</h2>  
                        <ul class="list-unstyled  light-300">
                        
                            <li class="pb-2">
                               <Link to="/" onClick={() => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}>  <div class="text-decoration-none bx-fw bx bxs-chevron-right bx-xs" style={{color:"black"}} >Home</div></Link>
                            </li>
                            <li class="pb-2">
                                <Link to="/About" onClick={() => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}><div class="text-decoration-none bx-fw bx bxs-chevron-right bx-xs " style={{color:"black"}}>About</div></Link>
                            </li>
                           
                            <li class="pb-2">
                               <Link to="/Contactus"onClick={() => { window.scrollTo({top: 0, left: 0, behavior: 'smooth'})}}><div class="text-decoration-none  py-1 bx-fw bx bxs-chevron-right bx-xs" style={{color:"black"}} >Contact</div></Link>
                            </li>
                            
                        </ul>
                </div>

                

                <div class="col-lg-4 col-md-4 my-sm-5 mt-4">
                    <h2 class="h4 pb-lg-3  light-300">For Client</h2>
                    <ul class="list-unstyled light-300">
                        <li class="pb-2">
                            <i class='bx-fw bx bx-phone bx-xs'></i><a class="text-decoration-none  py-1" href="tel:+91 6364002475" style={{color:"black"}}>+91 6364002475</a>
                        </li>
                        <li class="pb-2">
                           <span> <i class='bx-fw bx bx-mail-send bx-xs'></i><a class="text-decoration-none  py-1" href="mailto:: orbitenergyanalytics@gmail.com" style={{color:"black"}}>orbitenergyanalytics@gmail.com</a></span>
                        </li>
                    </ul>
                </div>

            </div>
              </div>

        <div class="w-100  py-3" style={{backgroundColor:"purple"}}>
            <div class="container">
                <div class="row pt-2">
                    <div class="col-lg-6 col-sm-12">
                        <p class="text-lg-start text-center text-light light-300">
                            © Copyright 2023 ORBIT Energy Analytics. All Rights Reserved.
                        </p>
                    </div>
                    <div class="col-lg-6 col-sm-12">
                        <p class="text-lg-end text-center text-light light-300">
                            Designed by <a rel="sponsored" class="text-decoration-none text-light" href="http://www.cynefian.com/"  target="_blank"><strong>CYNEFIAN</strong></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
      

    </footer>
    </div>
  );
}

export default Footer;