import React from "react";
import Content from "../Services/Content";



function Home() {
    return (
      <div>
      <div class="banner-wrapper " >
        <div  class="banner-vertical-center-index text-align: center container-fluid pt-4">
        <div id="index_banner" class="banner-vertical-center-index container-fluid pt-5">
            <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
                <ol class="carousel-indicators">
                    
                </ol>
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <div class="py-5 row d-flex align-items-center">
                            <div class="banner-content col-lg-8 col-8 offset-2 m-lg-auto text-left py-5 pb-5">
                                <h1 class="banner-heading h1  display-3 mb-0 pb-5 mx-0 px-0 light-30 typo-space-line " style={{color: "purple", fontWeight:"bold"}}>
                                Energy Efficiency to Higher Orbit
                              </h1>
                                <p class=" py-2 h3 mx-0 " style={{color: "purple", fontWeight:"bold"}}>
                                ORBIT provide services to improve energy performance of a facility, resulting in energy and cost savings, without affecting current activity.
                              </p>
                                
                            </div>
                        </div>

                    </div>
                   
                </div>
                </div>
            </div>
        </div>
       </div>
       <section class="service-wrapper py-3">
        <div class="container-fluid pb-3">
            <div class="row">
                <h2 class="h2 text-center col-12 py-2 semi-bold-600">Services</h2>
                <div class="service-header col-2 col-lg-3 text-end light-300">
                   
                </div>
                <div class="service-heading text-center col-2 col-lg-12  float-end light-30">
                    <h2 class="h3  py-4 ">ORBIT Energy provide following services in pursuit of a comprehensive energy audit.</h2>
                </div>
            </div>
        </div>

    </section>
  <Content class="py-0"/>
    <section class="py-2 banner-bg " >
        <div class="container my-4">
            <div class="col-lg-12 align-self-end">
                <p class=" pb-0 light-30" >
                    <h2 class="h2 pb-2">ORBIT has worked with various type of systems including</h2>
                    
                    <ul style={{liststyletype: "square"}}>
                    <li> Chilled Water System</li>
                    <li>Heating Hot Water System</li>
                    <li>Air Handling Units / Air Conditioning Units</li>
                    <li>Computer Room Air Handling / Air Conditioning Systems</li>
                    </ul>
                    </p> 
                <br />
            </div>
         </div>
    </section>
</div>
    
);
};
export default Home;