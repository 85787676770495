import React from "react";

function Contactus() {
  return (
    <section class="bg-160px">
    <div class="container py-4">
        <div class="row align-items-center justify-content-between">
            <div class="contact-header col-lg-4">
                <h1 class="h2 pb-3"  style={{color:"purple"}}>Contact Us</h1>
                
                <p class="light-300">
                Adress: Kuvempunagar, Mysore, Karnataka
                Website: www.orbit-energy.in<br />
                 Email: orbitenergyanalytics@gmail.com<br />
                 Phone: +91 6364002475
                </p>
            </div>
            <div class="contact-img col-lg-7 align-items-end col-md-4">
                <img src="./assets/img/contact5.png" alt=""/>
            </div>
        </div>
    </div>
</section> 
  );
}

export default Contactus;
