import React from "react";
import { HashRouter, Route, Routes, } from "react-router-dom";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Contactus from "./pages/Contactus";
import Home from "./pages/Home";
import Footer from "./components/Footer";


function App() {
  return (
    <>
     <HashRouter>
        <Navbar />
        <Routes>
        <Route path="/" exact element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contactus" element={<Contactus />} />
         
          
        </Routes>
        <Footer />
        </HashRouter>
    </>
  );
}

export default App;
