import React from "react";
import { Link } from "react-router-dom";
import logo1 from "../logo1.png";
import rachana1 from "../rachana1.png";



function Navbar() {
 

  return (
    <>
      <nav id="main_nav" class="navbar navbar-expand-lg navbar-light bg-white shadow ">
        <div class="container d-flex col-lg-7 justify-content-between align-items-center">
        <Link to="/"> <img src={logo1} alt="logo"style={{width:"300px",height:"90px",marginLeft:"160px", backgroundColor: 'transparent'}}/></Link>
        </div>
             <div class=" flex-fill  justify-content-lg-between" id="navbar-toggler-success">
              
                    <ul class="nav navbar-nav d-flex  mx-xl-2 justify-content: center justify-content-between align-items-center col-lg-11 text-center  text-dark">
                   
                    <li><Link to="/"><div  class="text-decoration-none text-dark bx-fw bx   "  >Home</div></Link></li>
                    <li><Link to="/About"><div  class="text-decoration-none text-dark bx-fw bx " >About</div></Link></li>
                       <li><Link to="/Contactus"><div class="text-decoration-none text-dark bx-fw bx "  >Contact</div></Link></li>
                       <a class=" align-right navbar-brand" rel="noopener" href="https://rachanaenercare.com" target="_blank">
                            <img src={rachana1} alt="logo" style={{width:'80px'}}/><br />
                           <span class=" text-center h6">Part of RACHANA Ener Care Group</span> <span class="text-light h3 semi-bold-600"></span>
                      </a>
                    </ul>
                </div>
        
        
    </nav>
    </>
  );
}

export default Navbar;
