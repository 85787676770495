import React from "react";



function About() {
  return (
   
      <div>
         <section class="bg-160px w-100">
        <div class="container">
            <div class="row d-flex align-items-center py-4">
                <div class="col-lg-6 text-start">
                    <h1 class="h2 py-3"  style={{color:"purple"}}>About Us</h1>
                    <p class="light-30">
                    RACHANA Ener Care Group is an energy services company based in Mysore. ORBIT and RACHANA are part consortium RACHANA Ener Care Group. The group provides consultancy and audit services in 4 major domains:
                    <ul style={{liststyletype: "square"}}>
                     <li>I – Innovation and Startup Mentoring</li>
                     <li>C – Consultancy Services in HVAC&R</li>
                     <li>A – Audit support in Energy Efficiency and Environment Health and Safety (EHS)</li>
                     <li>T – Training for students, and Working professionals </li>
                    </ul>
                    ORBIT Energy Analytics is an energy consultancy, providing services in the
                    field of energy efficiency and energy analytics.
                       ORBIT analyses building energy
                      usage and suggest ways to optimizes it, resulting in cost and energy savings.
                      ORBIT’s primary focus is in identifying low-cost retro-commissioning (RCx)
                      opportunities in commercial and industrial facilities..
                    </p>
                </div>
                <div class="col-lg-6">
                    <img src="./assets/img/rp.jpg" alt="" style={{width:"750px"}}/>
                </div>
            </div>
        </div>
    </section>
     <section class="bg-160px py-0">
        <div class="feature-work container my-0">
            <div class="row d-flex d-flex align-items-center">
                <div class="col-lg-9">
                   
                    <h2 class="col-12 col-xl-8 h4 text-left regular-400">ORBIT has experience in working with different control systems such as:</h2>
                    <p  class="feature-work-body  light-30 ">
                    <ul style={{liststyletype: "square"}}>
                     <li> ALC</li>
                     <li>Siemens</li>
                     <li>Johnson Controls</li>
                    </ul>
                    </p>  
                    </div> 
                    </div>

     <div class="d-flex">     
        <div class="col-6">
            <img src="./assets/img/pr.jpg" alt=""/>
         </div>

        <div class="col-7">
        <section class="bg-160px w-100">
            <div class="container">
       
             <div class=" align-items-center  py-8">
             <div class=" text-start ">
             <div class=""> 
                <h1 class="col-5 col-xl-12 h2 text-left pt-3" style={{color:"purple"}}>ORBIT’s key offering includes:</h1>
                    
                    <p class="col-4 col-xl-12 text-left  pb-5 light-30">
                     <ul style={{liststyletype: "square"}}>
                        <li>Field Instrumentation</li>
                         <li> Setup data trends and extract data from the existing control system</li>
                         <li> Data Analytics</li>
                        <li> Creating spreadsheet models to quantify energy savings associated with the identified with energy conservation measures (ECMs)</li>
                        <li> Provide implementation support</li>
                         <li>Post implementation, perform measurement and verification (M&V) using IPMVP protocol, to ascertain proper implementation</li>
                        <li>Identify key performance parameters (KPMs) for continuous monitoring to sustain energy and cost savings over extended period of times.</li>
                        <li>Create a spreadsheet Energy Information System (EIS) tool to keep track of all the energy usage from multiple sources at one centralized location</li>
                         <li>Create a spreadsheet tool for calculating Green House Gas (GHG) Emissions (Scope -1,2 and 3) from the energy usage at the facility.</li>
                    </ul></p>
                    </div>
                </div>
                
            </div>
        </div>
        </section>
            
        </div>


    </div>


        <h1 class="col-2 col-xl-8 h2 text-left  pt-3"  style={{color:"purple"}}>Facility Types:</h1>
        <h2 class="col-12 col-xl-12 h4 text-left regular-400">ORBIT Energy has the ability to provide, ASHRAE Level I, II, and III audits at different types of facilities such as:</h2>
        <p class="col-12 col-xl-20 text-left  pb-5 light-30">
        <ul style={{liststyletype: "square"}}>
             <li> Commercial Buildings</li>
             <li> Industrial Facilities</li>
             <li> Data Centres</li>
             <li>Hospitals</li>
             <li>University Campus Buildings</li>
             <li>Labs / Clean Rooms</li>
            </ul>
          </p>              
          </div>
    </section>
    </div>
  );
}

export default About;
